const firebaseConfig = {
  apiKey: "AIzaSyCOk_5dyz122SxHNYCXgOSFI1XdniLY7Lc",
  authDomain: "ld-ninja.firebaseapp.com",
  projectId: "ld-ninja",
  storageBucket: "ld-ninja.appspot.com",
  messagingSenderId: "1075705153886",
  appId: "1:1075705153886:web:e371068eacb7ba5a2aa7cf",
  measurementId: "G-BXF38BBP5L"
}

export default firebaseConfig
